<template>
  <div>
    <div class="cpan_xiangq">
      <img class="nimen_Ge" src="@/assets/style/images/images_s.jpg" alt="">
      <div class="zhongj_neir">
        <div class="left"><img :src="httpPath + goods.image" alt=""></div>
        <div class="right">
          <h2>{{goods.name}}</h2>
          <p>产品型号：<span>{{goods.code}}</span></p>
          <p>产品规格：<span>{{goods.specification}}</span></p>
          <p>产品价格：
            <span v-if="goods.price>0">{{goods.price}}元</span>
            <span v-else>面议</span>
          </p>
<!--          <a href="#" class="zaixzx">在线咨询</a>-->
<!--          <div class="div_fenx">-->
<!--            <a href="#">浏览：1452</a>-->
<!--            <a href="#" class="fenx_k">分享</a>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="ziye_beij_gongy">
      <div class="biaoti_Sy vclsf_ge_xq vclsf_ge_color" >
        <h2><span>详情</span></h2>
      </div>
      <div class="sanfenz_GE">
        <div v-html="goods.description"></div>
<!--        <img src="@/assets/style/images/img_ges_03.jpg" alt="">-->
      </div>
    </div>
  </div>

<!--  <div class="wrap-lg">-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 20px 0">-->
<!--      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item :to="{ path: '/mall' }">产品中心</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>{{goods.name}}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--    <el-card style="background: #ccc0; border: 1px solid #ffffff50;">-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :span="10" style="text-align: center;padding-top: 40px;">-->
<!--          <img :src="httpPath + goods.image" style="width: 80%"/>-->
<!--        </el-col>-->
<!--        <el-col :span="14" style="padding-left: 50px;min-height: 300px;">-->
<!--          <h2>{{goods.name}}</h2>-->
<!--          <p>产品型号：<span>{{goods.code}}</span></p>-->
<!--          <p>产品规格：<span>{{goods.specification}}</span></p>-->
<!--          <p>产品价格：-->
<!--            <span v-if="goods.price>0">{{goods.price}}元</span>-->
<!--            <span v-else>面议</span>-->
<!--          </p>-->
<!--&lt;!&ndash;          <p v-for="item in goods.goodsClassiResponseList" :key="item.id">&ndash;&gt;-->
<!--&lt;!&ndash;            {{item.classificationName}}:&ndash;&gt;-->
<!--&lt;!&ndash;            <span v-for="(subItem) in item.goodsCategoryResponseList" :key="subItem.id">&ndash;&gt;-->
<!--&lt;!&ndash;              <span style="margin-right: 10px;margin-left: 10px">{{subItem.categoryName}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </span>&ndash;&gt;-->
<!--&lt;!&ndash;          </p>&ndash;&gt;-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-divider></el-divider>-->
<!--      <h3>产品介绍：</h3>-->
<!--      <div v-html="goods.description"></div>-->
<!--    </el-card>-->
<!--  </div>-->
</template>

<script>
import { getInfo } from '@/api/mall/goods'

export default {
  name: 'detail',
  data () {
    return {
      httpPath: '',
      goodsId: null,
      goodsclassif: this.commonUtils.sessionStorageGet('goodsclassif'),
      goods: {}
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.goodsId = this.$route.query.sid
    this.getInfo()
  },
  filters: {
    filtersCategory: function (val) {
    }
  },
  methods: {
    toPage (path) {
      this.$router.push({
        name: path,
        service: {}
      })
    },
    getInfo () {
      let param = {}
      param.id = this.goodsId
      getInfo(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.goods = result.bizData
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
  color: #4f080e;
}
  h4{
    border-left: solid 6px #32A08C;
    padding-left: 10px;
  }
  .left img {
    height: 100%;
    width: auto;
  }
</style>
